import '../helpers/helpers';

import * as scrollEvent from '../eventHandlers/scrollEvent';

var svgPanZoom = require('svg-pan-zoom');

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	// function checkUrlStolarka(){
	// 	if(window.location.href.indexOf('?building=') > 0){
	// 		var $dataSigne = $('.polygon-description').attr('data-sign');

	// 		$('.floor-buttons[data-sign="'+$dataSigne+'"] li').removeClass('active');

	// 		if($('.polygon-description').attr('data-floor') == 0) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="0"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="0"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="0"]').css('display','inline-block');
	// 		} else if($('.polygon-description').attr('data-floor') == 1) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="1"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="1"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="1"]').css('display','inline-block');

	// 		} else if($('.polygon-description').attr('data-floor') == 2) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="2"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="2"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="2"]').css('display','inline-block');
	// 		}

	// 		document.getElementById('plan_map_stolarska').addEventListener('load', function(){
	// 			// svgPanZoom(document.getElementById('plan_map3'));
			  
	// 			var container = '#plan_map_stolarska';
	// 			var elem = $(container).find('.polygon-element');
	// 			var pos = $(elem).offset();
	// 			var parentOffset = $(container).offset();

	// 			pos.top -= parentOffset.top;
	// 			pos.left -= parentOffset.left;


	// 			{
	// 		      let width = $(container).width();
	// 		      let height = $(container).height();

	// 		      panZoom.panBy({x: width/2 - position.left, y: height/2 - position.top});
	// 		      panZoom.zoomBy(4);
	// 		   	}
	// 		});
	// 	}
	// }

	// function checkUrlPogodna(){
	// 	if(window.location.href.indexOf('?building=') > 0){
	// 		var $dataSigne = $('.polygon-description').attr('data-sign');

	// 		$('.floor-buttons[data-sign="'+$dataSigne+'"] li').removeClass('active');

	// 		if($('.polygon-description').attr('data-floor') == 0) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="0"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="0"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="0"]').css('display','inline-block');
	// 		} else if($('.polygon-description').attr('data-floor') == 1) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="1"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="1"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="1"]').css('display','inline-block');

	// 		} else if($('.polygon-description').attr('data-floor') == 2) {
	// 			$('.floor-buttons[data-sign="'+$dataSigne+'"] li[data-floor="2"]').addClass('active');
	// 			$('.polygon-hld[data-sign="'+$dataSigne+'"][data-floor="2"]').show();
	// 			$('.polygon-description[data-sign="'+$dataSigne+'"][data-floor="2"]').css('display','inline-block');
	// 		}

	// 		document.getElementById('plan_map_pogodna').addEventListener('load', function(){
	// 			// svgPanZoom(document.getElementById('plan_map3'));
			  
	// 			var container = '#plan_map_pogodna';
	// 			var elem = $(container).find('.polygon-element');
	// 			var pos = $(elem).offset();
	// 			var parentOffset = $(container).offset();

	// 			pos.top -= parentOffset.top;
	// 			pos.left -= parentOffset.left;


	// 			{
	// 		      let width = $(container).width();
	// 		      let height = $(container).height();

	// 		      panZoom.panBy({x: width/2 - position.left, y: height/2 - position.top});
	// 		      panZoom.zoomBy(2.5);
	// 		   	}
	// 		});
	// 	}
	// }

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	$('.btn--reservation').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		$('.popup-hld--reservation').addClass('js-show');
	});

	if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
		$('.popup-hld--startpage').removeClass('js-show');

		$('#polygon-popup-hld').removeClass('js-show');
		$(document).find('.polygon-description-special').css('display', 'none');
		$(document).find('.polygon-description').css('display', 'none');
		$(document).find('.floor-buttons').css('display', 'none');
		$(document).find('.building').css('display', 'none');
		$(document).find('.polygon-hld').css('display', 'none');
	});

	$('.nav-popup-blank').on('click', function(){
		$('.holder').removeClass('active');
	});

	function isValidEmailAddress(emailAddress) {
	    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
	    return pattern.test(emailAddress);
	};

	$('#reservation_form').on('submit', function() {
		var form = $(this),
			action = form.attr('action'),
			name = $('#firstname').val(),
			lastname = $('#lastname').val(),
			email = $("#email").val(),
			phone = $("#phone").val();

		if(name !== "") {
	    	$(this).find(".required--name").hide();
	    } else {
	    	$(this).find(".required--name").show();
	    }

	    if(lastname !== "") {
	    	$(this).find(".required--lastname").hide();
	    } else {
	    	$(this).find(".required--lastname").show();
	    }

		if(!isValidEmailAddress(email)) {
	    	$(this).find(".required--email").show();
	    } else {
	    	$(this).find(".required--email").hide();
	    }

	    if(phone !== "") {
	    	$(this).find(".required--phone").hide();
	    } else {
	    	$(this).find(".required--phone").show();
	    }

		if(name !== "" && lastname !== "" && email !== "" && phone !== "" && isValidEmailAddress(email) && $('#my-recaptcha').val() === "" /*&& grecaptcha.getResponse() !== ""*/) {

			$.ajax({
				url: action,
				type: 'post',
				dataType: 'json',
				data: form.serialize(),		
				success: function(response) {
					
				}
			});

       	}
       	return false;

    });


	if($(window).width() < 700) {
		$('.filter').on('click', 'li', function(){
			if($(this).hasClass('show-filter')){
				$(this).parent().addClass('activate');
			};
			if($(this).hasClass('active')){
				$(this).removeClass('active');
			} else {
				$('.filter li').removeClass('active');
				$('.filter li').removeClass('active');

				$(this).addClass('active');
				$(this).addClass('active');
			}
		});
	};


	$('.use-free').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();

		var $dataSign = $(this).attr('data-sign');

		$('.polygon-description').css('display','none');

		// $('.polygon-description--sec-type--1-pietro[data-sign="'+$dataSign+'"]').insertAfter($('.el-villa[data-sign="'+$dataSign+'"]').children().eq(10).parent());
		// $('.polygon-description--sec-type--2-pietro[data-sign="'+$dataSign+'"]').insertAfter($('.el-villa[data-sign="'+$dataSign+'"]').children().eq(21).parent());

		$('.polygon-description--logo-type--1-pietro[data-sign="'+$dataSign+'"]').insertAfter($('.el-logo[data-sign="'+$dataSign+'"]').children().eq(10).parent());
		$('.polygon-description--logo-type--2-pietro[data-sign="'+$dataSign+'"]').insertAfter($('.el-logo[data-sign="'+$dataSign+'"]').children().eq(24).parent());

		if($(this).attr('data-status') === 'sprzedane') {
			return false;
		} else {
			$('#polygon-popup-hld').addClass('js-show');

			$('.polygon-description[data-sign="'+$dataSign+'"]').appendTo('#polygon-popup-inner').removeClass('js-show').css({'left':'','top':''});

			$('.polygon-description[data-sign="'+$dataSign+'"]').css('display','inline-block');
		}
	});

	$('.use-free').each(function(){
		var $self = $(this),
			$dataTypeVilla = $self.attr('data-type');

		if($dataTypeVilla === 'villa' || $dataTypeVilla === 'logo' || $dataTypeVilla === 'quattro') {
			var $dataSign = $self.attr('data-sign');

			$('.use-free[data-sign="'+$dataSign+'"').each(function(){
				var $self2 = $(this);

				if($self2.attr('data-status') === 'wolne') {
					$('.use-free[data-sign="'+$dataSign+'"][data-status="zarezerwowane"]').css('display', 'none');
					$('.use-free[data-sign="'+$dataSign+'"][data-status="sprzedane"]').css('display', 'none');

					$('.use-free[data-sign="'+$dataSign+'"][data-status="wolne"]').addClass('hide');
					$('.use-free[data-sign="'+$dataSign+'"][data-status="wolne"]').eq(0).removeClass('hide');
				}

				if($self2.attr('data-status') === 'zarezerwowane') {
					$('.use-free[data-sign="'+$dataSign+'"][data-status="sprzedane"]').css('display', 'none');

					$('.use-free[data-sign="'+$dataSign+'"][data-status="zarezerwowane"]').addClass('hide');
					$('.use-free[data-sign="'+$dataSign+'"][data-status="zarezerwowane"]').eq(0).removeClass('hide');
				}

				if($self2.attr('data-status') === 'sprzedane') {
					$('.use-free[data-sign="'+$dataSign+'"][data-status="sprzedane"]').addClass('hide');
					$('.use-free[data-sign="'+$dataSign+'"][data-status="sprzedane"]').eq(0).removeClass('hide');
				}
			});
		}
	});



	// if($('#plan_map_buk').length){
	// 	var beforePan;
	//     beforePan = function(oldPan, newPan){
	//       var stopHorizontal = false
	//         , stopVertical = false
	//         , gutterWidth = $(window).width() > 1366 ? 1500 : 1100
	//         , gutterHeight = 600
	//           // Computed variables
	//         , sizes = this.getSizes()
	//         , leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth
	//         , rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom)
	//         , topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight
	//         , bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom)

	// 	      if($(window).width() > 500) {
	// 		      var customPan = {};
	// 		      customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
	// 		      customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

	// 		      return customPan;
	// 	      } else {
	// 		      var customPan = {};
	// 		      return customPan;
	// 	      }
	//     }

	// 	if(window.location.href.indexOf('?building=') > 0){
	// 		var args = {
	// 			viewportSelector: '.viewport-1',
	// 		    zoomEnabled: true,
	// 		    controlIconsEnabled: false,
	// 		    fit: true,
	// 		    center: true,
	// 		    minZoom: 1.4,
	// 		    maxZoom: 5,
	// 		    eventsListenerElement: document.querySelector('#plan_map_stolarska .viewport-1')
	// 		};
	// 	} else {
	// 		var args = {
	// 			viewportSelector: '.viewport-1',
	// 		    zoomEnabled: true,
	// 		    controlIconsEnabled: false,
	// 		    fit: true,
	// 		    center: true,
	// 		    minZoom: 0.6,
	// 		    maxZoom: 5,
	// 		    beforePan: beforePan,
	// 		    eventsListenerElement: document.querySelector('#plan_map_stolarska .viewport-1')
	// 		};
	// 	}
		
	//     var panZoom = svgPanZoom('#plan_map_stolarska', args);

	//     panZoom.zoomBy(1.2);

	//     document.getElementById('zoom-in').addEventListener('click', function(ev){
 //          ev.preventDefault()

 //          panZoom.zoomIn()
 //        });

 //        document.getElementById('zoom-out').addEventListener('click', function(ev){
 //          ev.preventDefault()

 //          panZoom.zoomOut()
 //        });

	//     $('#plan_map_buk a').on('mousedown touchstart', function( e ) {
	// 		e.stopImmediatePropagation();

	// 		var $dataSign = $(this).attr('data-sign'),
	// 			$dataType = $(this).attr('data-type');

	// 		$('.polygon-description').css('display','none');

	// 		if($(this).attr('data-status') === 'sprzedane') {
	// 			return false;
	// 		} else {
	// 			$('#polygon-popup-hld').addClass('js-show');

	// 			$('.polygon-description[data-sign="'+$dataSign+'"]').appendTo('#polygon-popup-inner').removeClass('js-show').css({'left':'','top':''});

	// 			if($dataType == 'villa') {
	// 				$('#polygon-popup-inner').addClass('products');
	// 			} else {
	// 				$('#polygon-popup-inner').removeClass('products');
	// 			}

	// 			$('.polygon-description[data-sign="'+$dataSign+'"]').css('display','inline-block');

	// 			$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="0"]').css('display','inline-block');

	// 			$('.floor-buttons[data-sign="'+$dataSign+'"]').show();
	// 			$('.building[data-sign="'+$dataSign+'"][data-floor="0"]').show();
	// 			$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="0"]').show();

	// 			$('.floor-buttons[data-sign="'+$dataSign+'"]').on('click', 'li', function(){
	// 				$('.floor-buttons[data-sign="'+$dataSign+'"] li').removeClass('active');
	// 				$(this).addClass('active');

	// 				$('.building[data-sign="'+$dataSign+'"]').hide();
	// 				$('.polygon-hld[data-sign="'+$dataSign+'"]').hide();
	// 				$('.polygon-description[data-sign="'+$dataSign+'"]').hide();

	// 				if($(this).attr('data-floor') == 0) {
	// 					$('.building[data-sign="'+$dataSign+'"][data-floor="0"]').show();
	// 					$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="0"]').show();
	// 					$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="0"]').css('display','inline-block');
	// 				}

	// 				if($(this).attr('data-floor') == 1) {
	// 					$('.building[data-sign="'+$dataSign+'"][data-floor="1"]').show();
	// 					$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="1"]').show();
	// 					$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="1"]').css('display','inline-block');
	// 				}

	// 				if($(this).attr('data-floor') == 2) {
	// 					$('.building[data-sign="'+$dataSign+'"][data-floor="2"]').show();
	// 					$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="2"]').show();
	// 					$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="2"]').css('display','inline-block');
	// 				}
	// 			});

	// 			// Villa 1
	// 			$('.el-villa[data-sign="s/v-1"] .tit:contains("S/V-1/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa1-parter'));
	// 			$('.el-villa[data-sign="s/v-1"] .tit:contains("S/V-1/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa1-pietro1'));
	// 			$('.el-villa[data-sign="s/v-1"] .tit:contains("S/V-1/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa1-pietro2'));

	// 			// Villa 2
	// 			$('.el-villa[data-sign="s/v-2"] .tit:contains("S/V-2/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa2-parter'));
	// 			$('.el-villa[data-sign="s/v-2"] .tit:contains("S/V-2/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa2-pietro1'));
	// 			$('.el-villa[data-sign="s/v-2"] .tit:contains("S/V-2/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa2-pietro2'));
	// 		}
	// 	});

	// 	checkUrlStolarka();
	// }

	if($('#plan_map_buk').length){
		var beforePan;
	    beforePan = function(oldPan, newPan){
	      var stopHorizontal = false
	        , stopVertical = false
	        , gutterWidth = $(window).width() > 1366 ? 1300 : 1000
	        , gutterHeight = 1400
	          // Computed variables
	       //  , sizes = this.getSizes()
	       //  , leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth
	       //  , rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom)
	       //  , topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight
	       //  , bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom)

		      // if($(window).width() > 500) {
			     //  var customPan = {};
			     //  customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
			     //  customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

			     //  return customPan;
		      // } else {
			     //  var customPan = {};
			     //  return customPan;
		      // }
	    }

		if(window.location.href.indexOf('?building=') > 0){
			var args = {
				viewportSelector: '.viewport-1',
			    zoomEnabled: true,
			    controlIconsEnabled: false,
			    fit: true,
			    center: true,
			    minZoom: 1.2,
			    // maxZoom: 5,
			    eventsListenerElement: document.querySelector('#plan_map_buk .viewport-1')
			};
		} else {
			var args = {
				viewportSelector: '.viewport-1',
			    zoomEnabled: true,
			    controlIconsEnabled: false,
			    fit: true,
			    center: true,
			    minZoom: 1.2,
			    // maxZoom: 5,
			    beforePan: beforePan,
			    eventsListenerElement: document.querySelector('#plan_map_buk .viewport-1')
			};
		}
		
	    var panZoom = svgPanZoom('#plan_map_buk', args);

	    panZoom.zoomBy(1.2);

	    // document.getElementById('zoom-in').addEventListener('click', function(ev){
     //      ev.preventDefault()

     //      panZoom.zoomIn()
     //    });

     //    document.getElementById('zoom-out').addEventListener('click', function(ev){
     //      ev.preventDefault()

     //      panZoom.zoomOut()
     //    });

	    $('#plan_map_buk a').on('mousedown touchstart', function( e ) {
			e.stopImmediatePropagation();

			var $dataSign = $(this).attr('data-sign'),
				$dataType = $(this).attr('data-type');

			$('.polygon-description').css('display','none');

			if($(this).attr('data-status') === 'sprzedane') {
				return false;
			} else {
				$('#polygon-popup-hld').addClass('js-show');

				$('.polygon-description[data-sign="'+$dataSign+'"]').appendTo('#polygon-popup-inner').removeClass('js-show').css({'left':'','top':''});

				if($dataType == 'logo') {
					$('#polygon-popup-inner').addClass('products');
				} else {
					$('#polygon-popup-inner').removeClass('products');
				}

				$('.polygon-description[data-sign="'+$dataSign+'"]').css('display','inline-block');

				$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="0"]').css('display','inline-block');

				$('.floor-buttons[data-sign="'+$dataSign+'"]').show();
				$('.building[data-sign="'+$dataSign+'"][data-floor="0"]').show();
				$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="0"]').show();

				$('.floor-buttons[data-sign="'+$dataSign+'"]').on('click', 'li', function(){
					$('.floor-buttons[data-sign="'+$dataSign+'"] li').removeClass('active');
					$(this).addClass('active');

					$('.building[data-sign="'+$dataSign+'"]').hide();
					$('.polygon-hld[data-sign="'+$dataSign+'"]').hide();
					$('.polygon-description[data-sign="'+$dataSign+'"]').hide();

					if($(this).attr('data-floor') == 0) {
						$('.building[data-sign="'+$dataSign+'"][data-floor="0"]').show();
						$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="0"]').show();
						$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="0"]').css('display','inline-block');
					}

					if($(this).attr('data-floor') == 1) {
						$('.building[data-sign="'+$dataSign+'"][data-floor="1"]').show();
						$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="1"]').show();
						$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="1"]').css('display','inline-block');
					}

					if($(this).attr('data-floor') == 2) {
						$('.building[data-sign="'+$dataSign+'"][data-floor="2"]').show();
						$('.polygon-hld[data-sign="'+$dataSign+'"][data-floor="2"]').show();
						$('.polygon-description[data-sign="'+$dataSign+'"][data-floor="2"]').css('display','inline-block');
					}
				});

				// Logo 1
				// $('.el-logo[data-sign="B/LG-1"] .tit:contains("B/LG-1/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo-1-parter'));
				// $('.el-logo[data-sign="B/LG-1"] .tit:contains("B/LG-1/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo-1-pietro1'));
				// $('.el-logo[data-sign="B/LG-1"] .tit:contains("B/LG-1/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo-1-pietro2'));

				$('.el-logo[data-sign="B/LG-1"][data-floor="0"]').appendTo($('.polygon-hld-logo-1-parter'));
				$('.el-logo[data-sign="B/LG-1"][data-floor="1"]').appendTo($('.polygon-hld-logo-1-pietro1'));
				$('.el-logo[data-sign="B/LG-1"][data-floor="2"]').appendTo($('.polygon-hld-logo-1-pietro2'));
			}

			// // Villa 4
			// $('.el-villa[data-sign="m/v-4"] .tit:contains("M/V-4/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa4-parter'));
			// $('.el-villa[data-sign="m/v-4"] .tit:contains("M/V-4/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa4-pietro1'));
			// $('.el-villa[data-sign="m/v-4"] .tit:contains("M/V-4/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa4-pietro2'));

			// // Villa 5
			// $('.el-villa[data-sign="m/v-5"] .tit:contains("M/V-5/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa5-parter'));
			// $('.el-villa[data-sign="m/v-5"] .tit:contains("M/V-5/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa5-pietro1'));
			// $('.el-villa[data-sign="m/v-5"] .tit:contains("M/V-5/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa5-pietro2'));

			// // Villa 5
			// $('.el-villa[data-sign="m/v-6"] .tit:contains("M/V-6/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa6-parter'));
			// $('.el-villa[data-sign="m/v-6"] .tit:contains("M/V-6/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa6-pietro1'));
			// $('.el-villa[data-sign="m/v-6"] .tit:contains("M/V-6/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-villa6-pietro2'));

			// // Logo 2
			// $('.el-logo[data-sign="m/lg-5"] .tit:contains("M/LG-5/P/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo5-parter'));
			// $('.el-logo[data-sign="m/lg-5"] .tit:contains("M/LG-5/I/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo5-pietro1'));
			// $('.el-logo[data-sign="m/lg-5"] .tit:contains("M/LG-5/II/")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-logo5-pietro2'));

			// // Quattro 1
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/1")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/2")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/3")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/1")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/2")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/3")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/4")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-parter'));

			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/4")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/5")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/6")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/5")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/6")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/7")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/8")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro1'));

			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/7")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/8")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1A/9")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/9")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/10")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/11")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
			// $('.el-quattro[data-sign="m/q-1"] .tit:contains("M/Q-1B/12")').parent().parent().parent().parent().parent().parent().appendTo($('.polygon-hld-quattro1-pietro2'));
		});

		// checkUrlPogodna();
	}
});

// AOS.init({
// 	disable: 'mobile',
// 	easing: 'ease-out',
// 	duration: 700
// });

$(window).on('load', function() {
	if($('.sidebar').length) {
		setTimeout(function(){
			var sidebarHldHeight = $('.sidebar-hld').height();
			$('.sidebar-hld').css('height', sidebarHldHeight);
		}, 0);

		setTimeout(function(){
			var sidebar = new StickySidebar('.sidebar', {
		        containerSelector: '.sidebar-hld',
		        innerWrapperSelector: '.sidebar__inner',
		        topSpacing: 75,
		        bottomSpacing: 20
		    });
		}, 50);
	}
});